import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import Hero from "../components/hero"
import Content from "../components/content"

/**
 * GraphQL Query to retrieve template data
 *
 * @return {Object}
 */
export const NotFoundQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/hero.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Not Found Page
 * This page component is used to generate the Not Found page
 * This page will be fired when you try to access an undefined route
 *
 * @param {Object} props
 */
const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />

    <Hero type="simple" title="404" background={data.hero.sharp.fluid} />

    <Content title="Page not found">
      <p className="text-center">&nbsp;</p>
    </Content>

    <ContactForm
      email={data.site.siteMetadata.email}
      phone={data.site.siteMetadata.phone}
    />
  </Layout>
)

export default NotFoundPage
